.breadcrumbs-root {
    background-color: rgba(16, 37, 75, 0.93);
    border-top: 1px solid #2E4170;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: left;
}

.breadcrumbs-country {
    padding-left: 1rem;
    color: inherit;
}
.breadcrumbs-country:hover {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    text-decoration: underline;
}
.breadcrumbs-region {
    margin-left: 0.4rem;
    overflow-x: hidden;
  }
.breadcrumbs-region::before {
    content: "  -  ";
}
.breadcrumbs-cell {
    margin-left: 0.4rem;
    overflow-x: hidden;
  }
.breadcrumbs-cell::before {
    content: "  -  ";
}
.mapcategory2 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: inherit;
}