.right-panel {
    z-index: 3;
    width: 380px;
    min-width: 290px;
    position: absolute;
    top: 1vh;
    bottom: 2vh;
    right: 1vh;
    height: 97vh;
    background-color: rgba(1, 16, 51, 0.93);
    border-radius: 16px;
}

.right-panel .top-section {
    position: relative;
}

.right-panel .top-section .location {
    position: absolute;
    top: 35px;
    left: 20px;
    font-size: 11px;
    font-weight: 600;
    line-height: 11px;
    letter-spacing: 0.07em;
    text-align: left;
    vertical-align: middle;
    height: 11px;
    margin-right: 10px;
    display: flex;
}

.right-panel .top-section .location .location-icon {
    position: relative;
    height: 100%;
    display: inline-block;
    margin-right: 5px;
}

.right-panel .icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.right-panel .print-icon {
    width: 50% !important;
}

.right-panel .location-icon {
    width: 9px;
    height: 11px;
}

.right-panel .top-section .share {
    position: absolute;
    top: 20px;
    left: 250px;
    background-color: #243D78;
    height: 34px;
    width: 34px;
    border-radius: 17px;
}

.right-panel .top-section .print {
    position: absolute;
    top: 20px;
    left: 292px;
    background-color: #243D78;
    height: 34px;
    width: 34px;
    border-radius: 17px;
}

.right-panel .top-section .collapse {
    position: absolute;
    top: 20px;
    left: 334px;
    background-color: #243D78;
    height: 34px;
    width: 34px;
    border-radius: 17px;
}

.right-panel .top-section .title {
    position: relative;
    top: -125px;
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0.205em;
    text-align: center;
    text-transform: uppercase;    
}

.right-panel .top-section .sub-title {
    position: absolute;
    top: 152px;
    left: 81px;
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
}

.right-panel .top-section .sensitivity-score {
    position: absolute;
    top: 217px;
    left: 81px;
    height: 60px;
    width: 218px;
    border-radius: 8px;
    background: #102646;
    text-align: center;
}

.right-panel .top-section .sentitivity-score-title {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    color: #8095C6;
    margin: auto;
    margin-top: 12px;
}

.right-panel .top-section .sentitivity-score-value {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
}

.right-panel .sensitiviy-description {
    margin-top: 40px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.right-panel .tabs {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-top: 67px; 
}

.right-panel .tabs div {
    height: 60px;
    width: 169px;
    border-radius: 8px;
    line-height: 30px;
    background: #102646;
    color: #8095C6;

    margin-left: 10px;
    margin-right: 10px;

    padding-left: 10px;
    padding-right: 10px;
}

.right-panel .tabs3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-top: 37px; 
}

.right-panel .tabs3 div {
    height: 60px;
    width: 30%;
    border-radius: 8px;
    background: #102646;
    color: #8095C6;
    margin-left: 2%;
    margin-right: 1%;
}

.right-panel .tabs3 div:nth-child(3) {
    margin-right: 2%;
}

.right-panel .tabs div.active {
    background: #FFFFFF;
    color: #0E1D40;
}

.right-panel .tabs3 div.active {
    background: #FFFFFF;
    color: #0E1D40;
}

.right-panel .species-tab {
    max-height: 54%;
}

.right-panel .species-tab-body {
    max-height: 400px;
    overflow-y: scroll;
}

.right-panel .species-header {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #8095C6;
}

.right-panel .species-row {
    height: 72px;
    width: 96%;
    margin: auto;
    border-radius: 8px;
    background: #102646;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}
.right-panel .species-endanger-classification {
    font-family: 'Work Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #152F54;
    color: #8095C6;
    border-radius: 20px;
    height: 40px;
    width: 40px;
}

.right-panel .common-name {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}

.right-panel .latin-name {
    font-size: 11px;
    font-style: italic;
    font-weight: 300;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: left;
}

.right-panel .separation-row {
    height: 10px;
}

.right-panel .land-cover-panel {
    position: relative;
}

.right-panel .land-cover-panel .land-cover-labels {
    position: relative;
    top: -300px;
    padding: 10px;
    max-width: 40%;
    margin: auto;
    line-height: 14px;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 7px;
}

.right-panel .sites-tab {
    max-height: 54%;
}

.right-panel .sites-tab-body {
    max-height: 400px;
    overflow-y: scroll;
}

.right-panel .sites-header {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #8095C6;
}

.right-panel .sites-row {
    height: 72px;
    width: 96%;
    margin: auto;
    border-radius: 8px;
    background: #102646;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}

.right-panel .sensitive-features-tab-header {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #8095C6;
    padding: 15px;
}

.right-panel-minimized {
    z-index: 3;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 3vh;
    right: 3vh;
    background-color: #243D78;
    border-radius: 17px;
}

.right-panel-minimized .collapse {
    height: 34px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.right-panel-minimized .icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.landcover-urban {
    color: #D73027;
}

.landcover-agricultural {
    color: #F46D43;
}

.landcover-shrub {
    color: #FDAE61;
}

.landcover-herbaceous {
    color: #FEE090;
}

.landcover-bare {
    color: #FFFFBF;
}

.landcover-forest {
    color: #8AD293;
}

.landcover-moss {
    color: #E0F3F8;
}

.landcover-wetlands {
    color: #ABD9E9;
}

.landcover-water {
    color: #74ADD1;
}

.landcover-snow {
    color: #5692E1;
}

.green {
    color: green;
}

.yellow {
    color: yellow;
}

.orange {
    color: orange;
}

.red {
    color: red;
}

.sensitive-site-tab {
    background: #E92F2F !important;
    color: #FFFFFF !important;
}

.right-panel > div.progress {
    width: 100%;
}