.splash-video {
	position: relative;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.transition-video {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 10;
}

.home {
	position: absolute;
	top: 0;
	width: 100%;
}

.home::after {
	content: "";
	clear: both;
	display: table;
}

.home .left {
	width: 45%;
	float: left;
	padding-left: 120px;
	min-height: 100vh;
	text-align: left;
	color: white;
}

.home .title {
	padding-top: 24vh;
	min-height: 20vh;
}

.home .sub-title {
	min-height: 12vh;
	max-width: 500px;
}

.home .button-list {
	min-height: 5vh;
	gap: 2em;
	display: flex;
	flex-wrap: wrap;
}


.home .foot {
	height: 30vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 1rem;
}

.home .logo {
	max-height: 120px;
	max-width: 120px;
	position: relative;
}

.home .right {
max-height: 100vh;
	max-width: 48%;
	float: left;
	margin: auto;
}


.home .vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(5%);
}

.home .globe {
  max-width: 600px;
	height: auto;
  position: relative;
  top:-780px;
  left: 50px;
}

.home h2 {
	font-weight: 700;
	font-size: 50px;
	line-height: 69px;
	letter-spacing: 0.08em;
}

.home h4 {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 148.52%;
	letter-spacing: 0.03em
}

.home .btn {
	display: inline-block;
}

[class*="btn-"] {
	font-family: 'Work Sans';
	font-size: 18px;
	font-weight: 400;
	line-height: 23px;
	letter-spacing: 0em;
	text-align: center;
	text-decoration: none;
	border-radius: 50px;
	border: 1px solid white;
	padding: 15px 50px 15px 50px;
}

.home .btn-white {
	background-color: white;
	color: #000000;
}

.home .btn-white:hover {
	cursor: pointer;
	box-shadow: 0 0 10px 1px white;
}

.home .btn-clear {
	color: white;
	background-color: transparent;
}

.home .btn-clear:hover {
	cursor: pointer;
	box-shadow: 0 0 10px 1px white;
}
