.header {
  padding: 15px;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

.footer {
  background-color: #0C1B3D;
  color: white;
  min-height: 240px;
  width: 100%;
  padding-top: 30px;
  position: relative;
  top: -10px;
}

.contact-list {
  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.bli-logo {
  width: 209px;
  height: 67px;
  position: relative;
  top: 0;
  left: 0;
}

.join-mailing-list-label {
  padding-bottom: 20px;
}

.email-input {
  text-align: left;
  color: white;
  background: rgb(255, 255, 255, 0);
  border-top: 1px white inset;
  border-left: 1px white inset;
  border-bottom: 1px white inset;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: none;
  display: inline-block;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  margin-bottom: 20px;
  padding-right: 0;
  margin-right: 0;
  min-width: 400px;
}

.email-input:focus {
  outline: none;
}

.join-button {
  background-color: white;
  color: #0C1B3D;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  left: -20px;
  font-size: 18px;
  padding-top: 6px;
  padding-bottom: 7px;
  padding-left: 42px;
  padding-right: 42px;
  margin-left: 0;
  margin-bottom: 20px;
}

.join-button:hover {
	box-shadow: 0 0 10px 1px white;
}

.copyright {
  width: 100%;
  text-align: left;
  padding-left: 100px;
  padding-top: 40px;
  font-size: 14px;
  color:rgb(255, 255, 255, 0.5);
}

.legal-links {
  text-align: left;
  font-size: 14px;
}

.legal-links-header {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Hind Madurai';
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.footer .legal-links > ul > li > a {
  text-decoration: none;
  color: white;
}