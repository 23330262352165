
.left-main {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 60px 30px auto;
    border-radius: inherit;
}

.left-header {
   background-color: rgba(16, 37, 75, 0.8);
   border-top-left-radius: inherit;
   border-top-right-radius: inherit;
}

.left-content {
    color: white
}

.left-selection {
    background-color: #FFF;
}

.left-info {
    background-color: bisque;
}

.left-footer {
    background-color: rgb(8, 3, 58);
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.dropdown-backdrop {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
}