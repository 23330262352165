.customButton {
    height: 50px;
    width: 25vw;
    letter-spacing: 0.5px;
    background-color: transparent;
    color: white;
    border-radius: 0px;
    cursor: pointer;
    border: 1px solid white;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
}
.customButton:first-child {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}
.customButton:last-child {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.customButton:hover {
  background-color: white;
  color: #0F2044;
}

.active {
  color: #0F2044;
  background-color: white;
}
