.legal {
    min-height: 140vh;
    width: 100%;
    /*background: linear-gradient(311.38deg, #FFFFFF 19.01%, #F0F0F0 42.99%, #D6D6D6 84.67%);*/
    background: #F9F9F9;
    font-family: 'Work Sans';
    letter-spacing: 0em;
    display: flex;
    flex-direction: column;
    color: #0F2044;
}

.legal .head-bg {
    position: fixed;
    top: 0;
    width: 100%;
    height: 120px;
    background-color: rgba(240, 240, 240, 0.9);
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.legal .head {
    position: fixed;
    top: 0;
    width: 100%;
    /*height: 120px;*/
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

    /*
    color: white;
    */
    height: 90px;
    background-color: #F9F9F9;
    z-index: 1;
    background: rgba(255, 255, 255, 0.01);
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);

}

.legal .avistep-logo {
    font-family: 'Hind Madurai';
    font-size: 30px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0.08em;
    padding-left: 25px;
    color: #0F2044;
    cursor: pointer;
}

.legal .info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
}

.legal .terms {
    max-width: 1110px;
}

.legal .terms-panel {
    width: 100%;
    padding-top: 200px;
}


.legal .privacy-panel {
    width: 100%;
    padding-top: 200px;
    padding-bottom: 100px;
}

.legal .cookies-panel {
    width: 100%;
    padding-top: 200px;
    padding-bottom: 100px;
}

.legal .button-group {
    display: inline-flex;
    flex-direction: row;
    top: 40px;
    position: relative;
}

.legal .customButton {
    height: 50px;
    width: 25vw;
    letter-spacing: 0.5px;
    background-color: transparent;
    color: #0F2044;
    border-radius: 0px;
    cursor: pointer;
    border: 1px solid #0F2044;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    max-width: 350px;
}

.legal .active {
    color: white;
    background-color: #0F2044;
    border: 1.5px solid rgb(25, 26, 24);
}

.legal .customButton:hover {
    color: white;
    background-color: #0F2044;
    border: 1.5px solid rgb(25, 26, 24);
}

.legal .btn-map {
    height: 50px;
	background-color: #008BCD;
	color: white;
	border-radius: 50px;
	cursor: pointer;
	border: none;
	font-family: Work Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 0px;
	text-align: center;
	padding-right: 30px;
	padding-left: 30px;
	margin-top: auto;
	margin-bottom: auto;
	position: relative;
	left: -20px;
}

.legal .btn-map:hover {
    background-color: white;
    color: #06234C;
}
  
.legal h1 {
    font-size: 50px;
    line-height: 64px;
    letter-spacing: 0.07em;
    text-align: left;
    color: #0F2044;
}

.legal .sub-title {
    font-family: 'Hind Madurai';
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
}

.legal h3 {
    font-size: 24px;
    line-height: 20px;
    text-align: left;
    color: #0F2044;
    padding-top: 45px;
}

.legal h5 {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    color: #0F2044;
}

.legal p {
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;
}

.legal ul {
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;
    color: #0F2044;
}

.legal li > a {
    color: blue;
}


.legal hr {
    width: 541px;
    height: 0px;
    opacity: 0.2;
    border: 1px solid #0F2044;
    margin-top: 50px;
}

.no-bullet {
    list-style-type: none;
}