.menu-root {
    display: grid;
    grid-template-rows: 10% 50%;
    height: 100%;
    color: white
}

.menu-head {
    padding: 1rem;
    font-size: 16px;
}
.menu-head > * + * {
    padding-left: 1rem;
    float: left;
}

.menu-navlist {
    display: grid;
    grid-template-rows: repeat(auto-fill, 13%);
    justify-items: stretch;
}

.menu-link {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.04em;
    text-decoration: none;
    cursor: pointer;
    text-align: left;
    padding: 1rem;
    color: white;
    width: 100%;
}
.menu-link:hover {
    transition: all 0.2s ease-in-out;
    background: #FFF;
    color: rgba(1, 16, 51, 0.9);
}

.closeBtn:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}