.energy-root {
    display: grid;
    grid-template-rows: 15fr 85fr;
}

.energy-list {
    display: grid;
    grid-template-rows: auto;
    row-gap: 1rem;
    padding: 0 1rem;
}

.energy-label {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding-left: 1rem;
    text-align: left;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #FFFFFF;
}

.energycard-root {
    background-color: rgba(16, 37, 75, 1);
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    color: rgba(161, 187, 247, 1);
    border: 1px solid transparent;
}

.energycard-root:hover {
    cursor: pointer;
    border: 1px solid #0083CD;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.energycard-label {
    padding-left: 1rem;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #A1BBF7;

}

.energycard-icon {
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
}