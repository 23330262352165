input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }
  .tog {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }
  
  .toglabel {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 21px;
    background: #27406D;
    display: block;
    border-radius: 16px;
    position: relative;
  }
  
  .toglabel:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 17px;
    height: 17px;
    background: #fff;
    border-radius: 17px;
    transition: 0.1s;
  }
  
  input:checked + .toglabel {
    background: #0083CD;
  }
  
  input:checked + .toglabel:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .toglabel:active:after {
    width: 23px;
  }