@import url(https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/dark/main.css);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  min-height: 100%;
}

body {
  min-height: 100vh;
  color: white;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Hind Madurai';
  font-style: normal;
  font-weight: 700;
  font-size: 76px;
  line-height: 98px;
  text-transform: uppercase;
  color: #FFFFFF;
}

h2 {
  font-family: 'Hind Madurai';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 65px;
  color: #FFFFFF;
}

h3 {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 41px;
  color: #FFFFFF;
}

h4 {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  color: #FFFFFF;
}

h5 {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}

a.button {
  -webkit-appearance: button;
  appearance: button;
  text-decoration: none;
}

@font-face {
  font-display:auto;
  font-family:Hind Madurai;
  font-style:normal;
  font-weight:300;
  src:local("Hind Madurai Light"),
  local("HindMadurai-Light"),
  url(/static/media/HindMadurai-Light.d9dcf650.ttf) format("truetype");
 }
 @font-face {
  font-display:auto;
  font-family:Hind Madurai;
  font-style:normal;
  font-weight:400;
  src:local("Hind Madurai"),
  local("HindMadurai"),
  url(/static/media/HindMadurai-Regular.f6364383.ttf) format("truetype");
 }
 @font-face {
  font-display:auto;
  font-family:Hind Madurai;
  font-style:normal;
  font-weight:500;
  src:local("Hind Madurai Medium"),
  local("HindMadurai-Medium"),
  url(/static/media/HindMadurai-Medium.0badd574.ttf) format("truetype");
 }
 @font-face {
  font-display:auto;
  font-family:Hind Madurai;
  font-style:normal;
  font-weight:600;
  src:local("Hind Madurai Semibold"),
  local("HindMadurai-Semibold"),
  url(/static/media/HindMadurai-SemiBold.00791199.ttf) format("truetype");
 }
 @font-face {
  font-display:auto;
  font-family:Hind Madurai;
  font-style:normal;
  font-weight:700;
  src:local("Hind Madurai Bold"),
  local("HindMadurai-Bold"),
  url(/static/media/HindMadurai-Bold.ef56d9ca.ttf) format("truetype");
 }

 @font-face {
  font-display:auto;
  font-family:Work Sans;
  font-style:normal;
  font-weight:300;
  src:local("Work Sans Light"),
  local("WorkSans-Light"),
  url(/static/media/WorkSans-Light.fae86bcc.ttf) format("truetype");
 }

 @font-face {
  font-display:auto;
  font-family:Work Sans;
  font-style:normal;
  font-weight:400;
  src:local("Work Sans Regular"),
  local("WorkSans-Regular"),
  url(/static/media/WorkSans-Regular.bb287b89.ttf) format("truetype");
 }

 @font-face {
  font-display:auto;
  font-family:Work Sans;
  font-style: italic;
  font-weight:200;
  src:local("Work Sans LightItalic"),
  local("WorkSans-LightItalic"),
  url(/static/media/WorkSans-LightItalic.985f4bf9.ttf) format("truetype");
 }

 @font-face {
  font-display:auto;
  font-family:Work Sans;
  font-style: italic;
  font-weight:500;
  src:local("Work Sans Italic"),
  local("WorkSans-SemiBoldItalic"),
  url(/static/media/WorkSans-SemiBoldItalic.4502a74f.ttf) format("truetype");
 }


.wrapper {
  height: 100%;
}

.content {
  background-color: #0F2044; /* For browsers that do not support gradients */
  height: 100%;
}

  
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 5vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #000000;
    padding-left: 20px;
    height: 8vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-title {
    padding-left: 40px;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  
.customButton {
    height: 50px;
    width: 25vw;
    letter-spacing: 0.5px;
    background-color: transparent;
    color: white;
    border-radius: 0px;
    cursor: pointer;
    border: 1px solid white;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
}
.customButton:first-child {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}
.customButton:last-child {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.customButton:hover {
  background-color: white;
  color: #0F2044;
}

.active {
  color: #0F2044;
  background-color: white;
}

.row::after {
	content: "";
	clear: both;
	display: table;
}

[class*="col-"] {
	float: left;
	padding: 15px;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

.about {
	min-height: 140vh;
	width: 100%;
	background-image: linear-gradient(to bottom right, #0F2044, #016098);
	position: relative;
    top: -10px;
}

.about .head {
	position: sticky;
    top: 0;
    width: 100%;
    height: 85px;
	display: flex;
	justify-content: space-between;
	color: white;
	margin-left: auto;
	margin-right: auto;
	background-color: #0F2044;
	z-index: 1;
	background: rgba(255, 255, 255, 0.01);
	-webkit-backdrop-filter: blur(11px);
	        backdrop-filter: blur(11px);
}

.about .avistep-logo {
    font-family: 'Hind Madurai';
    font-size: 30px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.08em;
    padding-left: 25px;
	cursor: pointer;
}

.about .button-group {
	display: inline-flex;
	flex-direction: row;
    position: relative;
    top: 20px;
	max-width: 60%;
}

.about .infocontainer {
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.about .infopanel {
	width: 100%;
	color: white;
	background-color: #0F2044;
	background-image: linear-gradient(to bottom right, #0F2044, #016098);
}

.about .acknowpanel {
	width: 100%;
	color: white;
	background-color: #016098;
	background-image: linear-gradient(to bottom right, #016098, #0F2044);
}

.about .contactpanel {
	min-height: 108vh;
	width: 100%;
	color: white;
	background-color: #0F2044;
	background-image: linear-gradient(to bottom right, #0F2044, #016098);
}

.about .btnlink {
	border: 1px solid white;
	padding: 15px 50px 15px 50px;
	border-radius: 0px;
	color: white;
	background-color: transparent;
	margin: 0;
	width: 100%;
}

.about .btnlink:first-child {
	border-top-left-radius: 30px !important;
	border-bottom-left-radius: 30px !important;
}

.about .btnlink:last-child {
	border-top-right-radius: 30px !important;
	border-bottom-right-radius: 30px !important;
}

.about .btnlink:hover {
	background-color: white;
	color: #06234C;
}

.about > div.head > div > button {
	max-width: 260px;
}

.about .btn_map {
	height: 50px;
	background-color: #008BCD;
	color: white;
	border-radius: 50px;
	cursor: pointer;
	border: none;
	font-family: Work Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 0px;
	text-align: center;
	padding-right: 30px;
	padding-left: 30px;
	margin-top: auto;
	margin-bottom: auto;
	position: relative;
	left: -20px;
}

.about .btn_map:hover {
	cursor: pointer;
	box-shadow: 0 0 10px 1px;
}

.about .general {
    font-family: 'Work Sans';
    text-align: left;
    padding-left: 150px;
    padding-right: 150px;
    letter-spacing: 0em;
    margin-top: 15px;
    text-align: left;
}

.about h1 {
    font-family: 'Hind Madurai';
    font-size: 50px;
    font-weight: 700;
    line-height: 75px;
}

.general h3 {
    font-size: 32px;
    font-weight: 400;
    line-height: 41px;
}

.general p {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
}

.general .image-set-1 {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
}

.general .image-set-1::after {
    content: '';
    clear: both;
    display: table;
}

.general .image-set-1 div {
    width: 50%;
    float: left;
    /*padding: 15px;*/
}

.general .image-set-1>div:nth-child(1) {
    padding-top: 20px;
	padding-right: 100px;
}

.general .image-set-1>div:nth-child(2) {
    text-align: right;
    padding-right: 0;
}

.general .image-set-1 .image-1 {
	width: 647px;
    height: auto;
}

.image-container {
	position:relative;
}

.image-caption {
    background: rgba(0, 0, 0, 0.7);
    position: relative;
    bottom: 38px;
    right: 0;
    padding-right: 10px;
    opacity: 0;
	transition:all 300ms ease-in-out;
   -webkit-transition:all 300ms ease-in-out;
   -moz-transition:all 300ms ease-in-out;
   -o-transition:all 300ms ease-in-out;
   -ms-transition:all 300ms ease-in-out;
   text-align: right;
}

.general .image-set-1 .image-caption {
	width: 647px;
    position: absolute;
    bottom: 8px;
}

.general .image-set-2 .image-caption {
	width: 1561px;
}

.image-container:hover .image-caption {
	opacity:1;
}

.general .image-set-2 {
    /*width: 100%;*/
	width: 1561px;
    padding-top: 100px;
    padding-bottom: 100px;
    margin: auto;
    text-align: center;
}

.general .image-set-2 .image-2 {
	max-width: 100%;
    height: auto;
  }
  
.general .group-set-3 {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
}

.general .group-set-3::after {
    content: '';
    clear: both;
    display: table;
}

.general .group-set-3 div {
	background: #E9E9E9;
	border-radius: 6px;
	font-family: 'Work Sans';
	font-size: 25px;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: center;
	color: #000000;
	vertical-align: middle;
}

.general .group-set-3 div:nth-child(1) {
    width: 30%;
    float: left;
    padding: 15px;
	height: 434px;
	margin-right: 50px;
	text-align: center;
}

.general .group-set-3 div:nth-child(2) {
    width: 60%;
    float: left;
    padding: 15px;
	height: 434px;
	margin-left: 50px;
}

.vertical-center {
	margin: 0;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.general .download-icon {
    width: 34px;
    height: 34px;
	position: absolute;
    bottom: 10px;
    left: 10px;
}

.general .ebook-icon {
    width: 34px;
    height: 34px;
	position: absolute;
    bottom: 10px;
    left: 55px;
}

.foot-note {
	font-family: 'Work Sans';
	font-size: 14px;
	font-weight: 400;
	/*line-height: 14px;*/
	letter-spacing: 0em;
	text-align: justified;
	padding-bottom: 20px;
	/*padding-top: 50px;*/
	line-height: 30px;
}

.acknowledgement {
    font-family: 'Work Sans';
    text-align: left;
    padding-left: 150px;
    padding-right: 150px;
    letter-spacing: 0em;
    margin-top: 200px;
}
  
.acknowledgement h2 {
    font-weight: 700;
}

.acknowledgement h3 {
    font-size: 32px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
}

.acknowledgement h5 {
    font-size: 24px;
    font-weight: 300;
    line-height: 0;
	padding-top: 30px;
    letter-spacing: 0em;
}

.acknowledgement div {
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;
}  

.acknowledgement .image-set-1 {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
}

.acknowledgement .image-set-1::after {
    content: '';
    clear: both;
    display: table;
}

.acknowledgement .image-set-1 div {
    width: 50%;
    float: left;
}

.acknowledgement .image-set-1 .image-1 {
	/*max-width: 90%;*/
	width: 755px;
    height: auto;
}

.acknowledgement a {
	color: white;
	text-decoration: none;
}

.acknowledgement .image-set-1 .image-caption {
	width: 755px;
}

.acknowledgement .image-set-1>div:nth-child(2) {
	text-align: right;
}

.acknowledgement .image-set-1>div:nth-child(2) {
	text-align: right;
}

.acknowledgement .image-set-1 .image-2 {
	/*max-width: 90%;*/
	width: 755px;
    height: auto;
}

.acknowledgement .image-set-2 {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    margin: auto;
    text-align: center;
}

.acknowledgement .image-set-2 .image-3 {
	width: 100%;
    height: auto;
}

.acknowledgement .logo-grid {
    width: 100%;
    padding: 100px 100px;
}

.acknowledgement .logo-grid .row {
    width: 100%;
}

.acknowledgement .logo-grid .row::after {
    content: '';
    clear: both;
    display: table;
}

.acknowledgement .logo-grid .row div {
    width: 20%;
    float: left;
    padding: 15px;
	text-align: center;
}

.acknowledgement .partner-logo {
    position: relative;
}

.contact {
	margin-top: 200px;
}

.contact .contactSectionHeader {
	display: block;
	margin-top: 100px;
	width: 100%;
	margin-left: 150px;
	font-family: Hind Madurai;
	font-size: 50px;
	font-weight: 700;
	line-height: 65px;
	letter-spacing: 0em;
	text-align: left;
}

.contact .contactUsSection {
	display: flex;
	margin-top: 50px;
}

.contact .contactUsFormColumn {
	flex: 35% 1;
	width: 80%;
	margin-left: 150px;
	margin-right: 50px;
}

.contact .contactInfoColumn {
	flex: 50% 1;
	width: 80%;
	margin-left: 100px;
	margin-right: 50px;
	font-size: 16px;
}

.contact .contactInfoSection {
	margin-top: 50px
}

.contact .contactInfoSectionHeader {
	text-align: left;
	font-weight: bold;
	text-transform: uppercase;
}

.contact .contactInfo {
	font-family: 'Work Sans';
	font-size: 20px;
	font-weight: 300;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
}

.contact input {
	font-family: 'Work Sans';
	text-align: left;
	color: white;
	width: 100%;
	background: rgb(255, 255, 255, 0);
	border: 1px white inset;
	font-size: 16px;
	padding-top: 13px;
	padding-bottom: 13px;
	padding-left: 18px;
	margin-bottom: 20px;
	border-radius: 4px;
}

.contact input::placeholder {
	color: white;
	opacity: 1;
}

.contact input:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: white;
}

.contact input::-ms-input-placeholder { /* Microsoft Edge */
	color: white;
}

.contact .message {
	font-family: 'Work Sans';
	text-align: left;
	color: white;
	width: 100%;
	height: 7em;
	background: rgb(255, 255, 255, 0);
	border: 1px white inset;
	font-size: 16px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 18px;
	margin-bottom: 20px;
	border-radius: 4px;
}

.contact .message::placeholder {
	color: white;
	opacity: 1;
}

.contact .message:-ms-input-placeholder {
	color: white;
}

.contact .message::-ms-input-placeholder {
	color: white;
}

.contact .submitButton {
	text-align: center;
	color: white;
	width: 100%;
	background: rgb(255, 255, 255, 0);
	border: 1px white inset;
	font-size: 18px;
	padding-top: 18px;
	padding-bottom: 18px;
	border-radius: 50px;   
}

.contact .submitButton:hover {
	box-shadow: 0px 0px 10px 1px white;
}

.contact .errorIcon {
	height: 16px;
	padding-right: 5px;
	padding-top: 5px;
}

.contact .errorMessage {
	position: relative;
	top: -20px;
	line-height: 1;
	padding: 0;
	margin: 0;
	text-align: left;
	color: white;
	font-size: 14px;
}

.modal-background {
    /*background-color: rgba(0,0,0,0.8);*/
	background-color: rgba(12, 27, 61, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.modal-container {
    position: relative;
    top: 380px;
    width: 600px;
    height: 325px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: #0C1B3D;
}

.exit-button {
	position: absolute;
    top: 15px;
    left: 15px;
    width: 16px;
    height: 16px;
/*    position: relative;
    top: -15px;
    left: -290px;
	*/
}

.modal-icon {
    width: 73px;
    height: 73px;
}

.modal-header {
    font-family: Work Sans;
    font-size: 32px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
}

.modal-text {
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
	margin: 0 26px;
}

.outline-letters {
    color: transparent !important;
	-webkit-text-stroke-color: #ffffff;
    -webkit-text-stroke-width: 1px;
    text-transform: uppercase;
    letter-spacing: .1em;
	font-family: 'Hind Madurai';
	font-size: 45px;
	font-weight: 700;
	line-height: 65px;
	letter-spacing: 0em;
	text-align: left;
}

.general p.subsection-header {
	font-family: 'Hind Madurai';
	font-size: 45px;
	font-weight: 700;
	line-height: 70px;
	letter-spacing: 0em;
	text-align: left;
	max-width: 1205px;
}

.super-script {
	vertical-align: super;
	font-size: smaller;	
}

.relative {
	position: relative;
}

.help {
	margin-top: 200px;
	margin-bottom: 100px;
	padding-left: 150px;
	padding-right: 150px;
	text-align: left;
	font-size: 20px;
}

.help .small-paragraph {
	max-width: 775px;
    line-height: 36px;
	font-size: 20px;
    padding-bottom: 120px;
}

.help .video-place-holder {
	height: 725px;
	max-width: 1554px;
	border-radius: 6px;
	background-color: #E9E9E9;
	text-align: center;
	margin: auto;
	color: #000000;
	margin-bottom: 120px;
}

.help .video-place-holder div {
	text-align: center;
	color: #000000;
	vertical-align: middle;
	font-size: 25px;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: center;
}

.help .container .row > div:nth-child(1) {
	padding-left: 0;
    padding-right: 50px;
}

.help .container .row .right-border {
	border-right: 1px solid;
	border-right-color: rgba(255, 255, 255, 0.25);
}

.help .container .row > div:nth-child(2) {
	padding-left: 100px;
    padding-right: 0px;
}

.help .container .row {
	padding-bottom: 100px;
}

.help ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	padding-top: 40px;
	padding-bottom: 40px;
	padding-left: 50px;
}

.help li {
	line-height: 50px;
	position: relative;
}

.help li .icon {
	position: relative;
	vertical-align: middle;
	padding-right: 10px;
}

.help .sensitivity-card {
	height: 60px;
	width: 229px;
	border-radius: 8px;
	margin-top: 10px;
	margin-bottom: 10px;
    border: none;
}

.help .sensitivity-card:hover {
    transition: none;
    border: none;
	/*
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
	*/
	pointer-events: none;
}

.help .avistep-manual-place-holder {
	height: 392px;
	width: 308px;
	border-radius: 6px;
	background-color: #E9E9E9;
	text-align: center;
	margin: auto;
	color: #000000;
}

.help .avistep-manual-place-holder div {
	text-align: center;
	color: #000000;
	vertical-align: middle;
	font-size: 25px;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: center;
}

.help .container .row > div.col-9.relative {
	height: 392px;
	max-width: 775px;
}

.help .category-card-container {
	margin-top: 60px;
	margin-bottom: 100px;
}

.help .category-card {
	height: 107px;
	max-width: 912px;
	padding-left: 20px;
	border-radius: 8px;
	background-color: #10264699;
	margin-top: 10px;
	margin-bottom: 15px;
	margin-left: 50px;
}

.help .category-card-container .row.category-card div:nth-child(1) {
	font-size: 18px;
}

.help .category-card-container .row.category-card > div:nth-child(2) {
	font-size: 16px;
	line-height: 28px;
	padding-left: 0;
}

.help .category-card:nth-child(1) {
	border-left-width: 8px;
	border-left-style: solid;
	border-left-color: #4EC943;
}

.help .category-card:nth-child(2) {
	border-left-width: 8px;
	border-left-style: solid;
	border-left-color: #FFDB6B;
}

.help .category-card:nth-child(3) {
	border-left-width: 8px;
	border-left-style: solid;
	border-left-color: #F37945;
}

.help .category-card:nth-child(4) {
	border-left-width: 8px;
	border-left-style: solid;
	border-left-color: #F11919;
}
.header {
  padding: 15px;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

.footer {
  background-color: #0C1B3D;
  color: white;
  min-height: 240px;
  width: 100%;
  padding-top: 30px;
  position: relative;
  top: -10px;
}

.contact-list {
  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.bli-logo {
  width: 209px;
  height: 67px;
  position: relative;
  top: 0;
  left: 0;
}

.join-mailing-list-label {
  padding-bottom: 20px;
}

.email-input {
  text-align: left;
  color: white;
  background: rgb(255, 255, 255, 0);
  border-top: 1px white inset;
  border-left: 1px white inset;
  border-bottom: 1px white inset;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: none;
  display: inline-block;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  margin-bottom: 20px;
  padding-right: 0;
  margin-right: 0;
  min-width: 400px;
}

.email-input:focus {
  outline: none;
}

.join-button {
  background-color: white;
  color: #0C1B3D;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  left: -20px;
  font-size: 18px;
  padding-top: 6px;
  padding-bottom: 7px;
  padding-left: 42px;
  padding-right: 42px;
  margin-left: 0;
  margin-bottom: 20px;
}

.join-button:hover {
	box-shadow: 0 0 10px 1px white;
}

.copyright {
  width: 100%;
  text-align: left;
  padding-left: 100px;
  padding-top: 40px;
  font-size: 14px;
  color:rgb(255, 255, 255, 0.5);
}

.legal-links {
  text-align: left;
  font-size: 14px;
}

.legal-links-header {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Hind Madurai';
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.footer .legal-links > ul > li > a {
  text-decoration: none;
  color: white;
}
.splash-video {
	position: relative;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.transition-video {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 10;
}

.home {
	position: absolute;
	top: 0;
	width: 100%;
}

.home::after {
	content: "";
	clear: both;
	display: table;
}

.home .left {
	width: 45%;
	float: left;
	padding-left: 120px;
	min-height: 100vh;
	text-align: left;
	color: white;
}

.home .title {
	padding-top: 24vh;
	min-height: 20vh;
}

.home .sub-title {
	min-height: 12vh;
	max-width: 500px;
}

.home .button-list {
	min-height: 5vh;
	grid-gap: 2em;
	gap: 2em;
	display: flex;
	flex-wrap: wrap;
}


.home .foot {
	height: 30vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 1rem;
}

.home .logo {
	max-height: 120px;
	max-width: 120px;
	position: relative;
}

.home .right {
max-height: 100vh;
	max-width: 48%;
	float: left;
	margin: auto;
}


.home .vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(5%);
}

.home .globe {
  max-width: 600px;
	height: auto;
  position: relative;
  top:-780px;
  left: 50px;
}

.home h2 {
	font-weight: 700;
	font-size: 50px;
	line-height: 69px;
	letter-spacing: 0.08em;
}

.home h4 {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 148.52%;
	letter-spacing: 0.03em
}

.home .btn {
	display: inline-block;
}

[class*="btn-"] {
	font-family: 'Work Sans';
	font-size: 18px;
	font-weight: 400;
	line-height: 23px;
	letter-spacing: 0em;
	text-align: center;
	text-decoration: none;
	border-radius: 50px;
	border: 1px solid white;
	padding: 15px 50px 15px 50px;
}

.home .btn-white {
	background-color: white;
	color: #000000;
}

.home .btn-white:hover {
	cursor: pointer;
	box-shadow: 0 0 10px 1px white;
}

.home .btn-clear {
	color: white;
	background-color: transparent;
}

.home .btn-clear:hover {
	cursor: pointer;
	box-shadow: 0 0 10px 1px white;
}

.map {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;  
  text-align: center;
  z-index: 0;
}

.esri-view .esri-view-surface--inset-outline:focus::after {
  outline:none;
 }

.logo {
  position: absolute;
  z-index: 3;
  top: 2rem;
  right: 2rem;
}

.progress {
  position: absolute;
  z-index: 4;
  top:0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(16, 37, 75, 0.5);
  display:grid;
  align-items: center;
  justify-items: center;
}

.pdf-root {
    position: absolute;
    top: 5vh;
    left: 25vw;
    width: 50vw;
    height:90vh;
    z-index: 10;
    border-radius: 16px;
    background-color: rgba(16, 37, 75, 1);
    align-content: center;

}

.pdf-view {
    position: absolute;
    top: 0px;
    height: 99%;
    width: 99%;
    z-index: 12;
    border-radius: 16px;
    background-color: rgba(16, 37, 75, 1);
    display: grid;
    grid-template-rows: 10% 80% 10%;
    align-content: center;
    margin: 5px;
}

.pdf-maps {
    position: absolute;
    top: 0px;
    height: 99%;
    width: 99%;
    border-radius: 16px;
    z-index: 11;
    margin: 5px;
    display: grid;
    grid-template-rows: 20% 60% 20%;
    align-content: center;
    background-color: rgb(76, 76, 68);
}
.pdf-mapcontainer {
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.pdf-header {
    padding-top: 10px;
}
.pdf-viewer {
    width: 95%;
    height: 95%;
    margin-left: auto;
    margin-right: auto;
}
.pdf-progress {
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 80%;
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    color: #FFFFFF;
}
.pdf-footer {
    display: flex;
}

.pdf-close {
    height: 30px;
	background-color: #008BCD;
	color: white;
	border-radius: 30px;
	cursor: pointer;
	border: none;
	font-family: Work Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 0px;
	text-align: center;
	padding-right: 10px;
	padding-left: 10px;
	margin-top: auto;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;

}
.menu-root {
    display: grid;
    grid-template-rows: 10% 50%;
    height: 100%;
    color: white
}

.menu-head {
    padding: 1rem;
    font-size: 16px;
}
.menu-head > * + * {
    padding-left: 1rem;
    float: left;
}

.menu-navlist {
    display: grid;
    grid-template-rows: repeat(auto-fill, 13%);
    justify-items: stretch;
}

.menu-link {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.04em;
    text-decoration: none;
    cursor: pointer;
    text-align: left;
    padding: 1rem;
    color: white;
    width: 100%;
}
.menu-link:hover {
    transition: all 0.2s ease-in-out;
    background: #FFF;
    color: rgba(1, 16, 51, 0.9);
}

.closeBtn:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}
.l-header {
    background-color: rgba(16, 37, 75, 0.93);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    width: 100%;
    display: grid;
}

.l-header-root {
    font-size: 15px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    margin-left: 1rem;
    margin-right: 0.2rem;
}

.l-header-menu {
    color: inherit;
    display: grid;
}
.l-header-menu:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}
.l-header-title {
    color: inherit;
}

.MuiInput-underline::after {
    border-bottom: none;
}
.breadcrumbs-root {
    background-color: rgba(16, 37, 75, 0.93);
    border-top: 1px solid #2E4170;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: left;
}

.breadcrumbs-country {
    padding-left: 1rem;
    color: inherit;
}
.breadcrumbs-country:hover {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    text-decoration: underline;
}
.breadcrumbs-region {
    margin-left: 0.4rem;
    overflow-x: hidden;
  }
.breadcrumbs-region::before {
    content: "  -  ";
}
.breadcrumbs-cell {
    margin-left: 0.4rem;
    overflow-x: hidden;
  }
.breadcrumbs-cell::before {
    content: "  -  ";
}
.mapcategory2 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: inherit;
}
.country-root {
    display: grid;
    grid-template-rows: 20fr 80fr;
}

.country-list {
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding: 0 1rem;
}

.country-label {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding-left: 1rem;
    text-align: left;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #FFFFFF;
}

.countrycard-root {
    background-color: rgba(16, 37, 75, 0.93);
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    color: rgba(161, 187, 247, 1);
    border: 1px solid transparent;
}

.countrycard-root:hover {
    cursor: pointer;
    border: 1px solid #0083CD;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.countrycard-label {
    padding-left: 1rem;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #A1BBF7;

}

.countrycard-flag {
    padding-right: 1rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}
.energy-root {
    display: grid;
    grid-template-rows: 15fr 85fr;
}

.energy-list {
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding: 0 1rem;
}

.energy-label {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding-left: 1rem;
    text-align: left;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #FFFFFF;
}

.energycard-root {
    background-color: rgba(16, 37, 75, 1);
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    color: rgba(161, 187, 247, 1);
    border: 1px solid transparent;
}

.energycard-root:hover {
    cursor: pointer;
    border: 1px solid #0083CD;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.energycard-label {
    padding-left: 1rem;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #A1BBF7;

}

.energycard-icon {
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
}


.sensitivity-root {
    padding: 1.5rem 1rem;
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 75px 45px auto 1fr 5fr 2fr;
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
}

.sensitivity-root .header {
    display: grid;
    grid-template-columns: 20% auto;
    text-align: left;
    align-items: center;
    justify-items: start;
    padding: 0px;
}

.sensitivity-root .header .backnav {
    height: 34px;
    width: 34px;
    border-radius: 17px;
    border: 1px solid #2E4170;
    display: grid;
    align-items: center;
    justify-items: center;
    
}
.sensitivity-root .header .backnav:hover {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border: 1px solid #0083CD;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.sensitivity-root .header .title {
    display: grid;
    align-items: center;
    justify-items: start;
    text-align: left;
}

.header .title .title1 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #A1BBF7;
}

.header .title .title2 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    color: #FFFFFF;
}

.sensitivity-root .desc {
    display: grid;
    align-items: center;
    justify-items: start;
}

.sensitivity-root .cards {
    display: grid;
    height: 300px;
    width: 100%;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    row-gap: 10px;
}

.sensitivity-root .suitable {
    display: grid;
    grid-gap: 17px;
    gap: 17px;
    align-items: center;
    justify-content: left;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #A1BBF6;
    grid-template-columns: 70% auto;
    text-align: left;
}


.sensitivity-root .transparency {
    display: grid;
    align-items: center;
    justify-items: start;
    text-align: left;
    width: 95%;
    
}

.sensitivity-card {
    display: grid;
    width: 100%;
    height: 60px;
    background: rgba(16, 38, 70, 0.6);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 8px;
    text-align: left;
    grid-template-columns: 8px 60% 2fr 2fr 1fr;
}

.sensitivity-card:hover {
    transition: all 0.2s ease-in-out;
    border: 1px solid #0083CD;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.sensitivity-card .labels {
    display: grid;
    align-content: center;
    align-items: center;
    justify-items: start;
    text-align: left;
    padding-left: 0.75rem;
}

.sensitivity-card .labels .label1 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 129.52%;
    color: #A1BBF7;
    opacity: 0.9;
}

.sensitivity-card .labels .label2 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #FFFFFF;
}

.sensitivity-card .percent {
    display: grid;
    align-items: center;
    justify-items: start;
    text-align: left;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 180%;
    color: #FFFFFF;
}

.sensitivity-card .toggle {
    display: grid;
    align-content: center;
    align-items: center;
    justify-items: start;
    text-align: left;
}


.bodytext3 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #8095C6;
    text-align: left;

}

.slider .MuiSlider-thumb {
    width: 19px;
    height: 19px;
    background: #A1BBF7;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    margin-top: -8px;
    margin-left: -8px;
}

.slider .MuiSlider-rail {
    width: 100%;
    height: 5px;
    background: #27406D;
    border-radius: 16px;
}

.slider .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 3px rgba(63, 81, 181, 0.2);
}
input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }
  .tog {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }
  
  .toglabel {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 21px;
    background: #27406D;
    display: block;
    border-radius: 16px;
    position: relative;
  }
  
  .toglabel:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 17px;
    height: 17px;
    background: #fff;
    border-radius: 17px;
    transition: 0.1s;
  }
  
  input:checked + .toglabel {
    background: #0083CD;
  }
  
  input:checked + .toglabel:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .toglabel:active:after {
    width: 23px;
  }

.left-main {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 60px 30px auto;
    border-radius: inherit;
}

.left-header {
   background-color: rgba(16, 37, 75, 0.8);
   border-top-left-radius: inherit;
   border-top-right-radius: inherit;
}

.left-content {
    color: white
}

.left-selection {
    background-color: #FFF;
}

.left-info {
    background-color: bisque;
}

.left-footer {
    background-color: rgb(8, 3, 58);
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.dropdown-backdrop {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
}
.right-panel {
    z-index: 3;
    width: 380px;
    min-width: 290px;
    position: absolute;
    top: 1vh;
    bottom: 2vh;
    right: 1vh;
    height: 97vh;
    background-color: rgba(1, 16, 51, 0.93);
    border-radius: 16px;
}

.right-panel .top-section {
    position: relative;
}

.right-panel .top-section .location {
    position: absolute;
    top: 35px;
    left: 20px;
    font-size: 11px;
    font-weight: 600;
    line-height: 11px;
    letter-spacing: 0.07em;
    text-align: left;
    vertical-align: middle;
    height: 11px;
    margin-right: 10px;
    display: flex;
}

.right-panel .top-section .location .location-icon {
    position: relative;
    height: 100%;
    display: inline-block;
    margin-right: 5px;
}

.right-panel .icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.right-panel .print-icon {
    width: 50% !important;
}

.right-panel .location-icon {
    width: 9px;
    height: 11px;
}

.right-panel .top-section .share {
    position: absolute;
    top: 20px;
    left: 250px;
    background-color: #243D78;
    height: 34px;
    width: 34px;
    border-radius: 17px;
}

.right-panel .top-section .print {
    position: absolute;
    top: 20px;
    left: 292px;
    background-color: #243D78;
    height: 34px;
    width: 34px;
    border-radius: 17px;
}

.right-panel .top-section .collapse {
    position: absolute;
    top: 20px;
    left: 334px;
    background-color: #243D78;
    height: 34px;
    width: 34px;
    border-radius: 17px;
}

.right-panel .top-section .title {
    position: relative;
    top: -125px;
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0.205em;
    text-align: center;
    text-transform: uppercase;    
}

.right-panel .top-section .sub-title {
    position: absolute;
    top: 152px;
    left: 81px;
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
}

.right-panel .top-section .sensitivity-score {
    position: absolute;
    top: 217px;
    left: 81px;
    height: 60px;
    width: 218px;
    border-radius: 8px;
    background: #102646;
    text-align: center;
}

.right-panel .top-section .sentitivity-score-title {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    color: #8095C6;
    margin: auto;
    margin-top: 12px;
}

.right-panel .top-section .sentitivity-score-value {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
}

.right-panel .sensitiviy-description {
    margin-top: 40px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.right-panel .tabs {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-top: 67px; 
}

.right-panel .tabs div {
    height: 60px;
    width: 169px;
    border-radius: 8px;
    line-height: 30px;
    background: #102646;
    color: #8095C6;

    margin-left: 10px;
    margin-right: 10px;

    padding-left: 10px;
    padding-right: 10px;
}

.right-panel .tabs3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-top: 37px; 
}

.right-panel .tabs3 div {
    height: 60px;
    width: 30%;
    border-radius: 8px;
    background: #102646;
    color: #8095C6;
    margin-left: 2%;
    margin-right: 1%;
}

.right-panel .tabs3 div:nth-child(3) {
    margin-right: 2%;
}

.right-panel .tabs div.active {
    background: #FFFFFF;
    color: #0E1D40;
}

.right-panel .tabs3 div.active {
    background: #FFFFFF;
    color: #0E1D40;
}

.right-panel .species-tab {
    max-height: 54%;
}

.right-panel .species-tab-body {
    max-height: 400px;
    overflow-y: scroll;
}

.right-panel .species-header {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #8095C6;
}

.right-panel .species-row {
    height: 72px;
    width: 96%;
    margin: auto;
    border-radius: 8px;
    background: #102646;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}
.right-panel .species-endanger-classification {
    font-family: 'Work Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #152F54;
    color: #8095C6;
    border-radius: 20px;
    height: 40px;
    width: 40px;
}

.right-panel .common-name {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}

.right-panel .latin-name {
    font-size: 11px;
    font-style: italic;
    font-weight: 300;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: left;
}

.right-panel .separation-row {
    height: 10px;
}

.right-panel .land-cover-panel {
    position: relative;
}

.right-panel .land-cover-panel .land-cover-labels {
    position: relative;
    top: -300px;
    padding: 10px;
    max-width: 40%;
    margin: auto;
    line-height: 14px;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 7px;
}

.right-panel .sites-tab {
    max-height: 54%;
}

.right-panel .sites-tab-body {
    max-height: 400px;
    overflow-y: scroll;
}

.right-panel .sites-header {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #8095C6;
}

.right-panel .sites-row {
    height: 72px;
    width: 96%;
    margin: auto;
    border-radius: 8px;
    background: #102646;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}

.right-panel .sensitive-features-tab-header {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #8095C6;
    padding: 15px;
}

.right-panel-minimized {
    z-index: 3;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 3vh;
    right: 3vh;
    background-color: #243D78;
    border-radius: 17px;
}

.right-panel-minimized .collapse {
    height: 34px;
    transform: scaleX(-1);
}

.right-panel-minimized .icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.landcover-urban {
    color: #D73027;
}

.landcover-agricultural {
    color: #F46D43;
}

.landcover-shrub {
    color: #FDAE61;
}

.landcover-herbaceous {
    color: #FEE090;
}

.landcover-bare {
    color: #FFFFBF;
}

.landcover-forest {
    color: #8AD293;
}

.landcover-moss {
    color: #E0F3F8;
}

.landcover-wetlands {
    color: #ABD9E9;
}

.landcover-water {
    color: #74ADD1;
}

.landcover-snow {
    color: #5692E1;
}

.green {
    color: green;
}

.yellow {
    color: yellow;
}

.orange {
    color: orange;
}

.red {
    color: red;
}

.sensitive-site-tab {
    background: #E92F2F !important;
    color: #FFFFFF !important;
}

.right-panel > div.progress {
    width: 100%;
}
.legal {
    min-height: 140vh;
    width: 100%;
    /*background: linear-gradient(311.38deg, #FFFFFF 19.01%, #F0F0F0 42.99%, #D6D6D6 84.67%);*/
    background: #F9F9F9;
    font-family: 'Work Sans';
    letter-spacing: 0em;
    display: flex;
    flex-direction: column;
    color: #0F2044;
}

.legal .head-bg {
    position: fixed;
    top: 0;
    width: 100%;
    height: 120px;
    background-color: rgba(240, 240, 240, 0.9);
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.legal .head {
    position: fixed;
    top: 0;
    width: 100%;
    /*height: 120px;*/
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

    /*
    color: white;
    */
    height: 90px;
    background-color: #F9F9F9;
    z-index: 1;
    background: rgba(255, 255, 255, 0.01);
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);

}

.legal .avistep-logo {
    font-family: 'Hind Madurai';
    font-size: 30px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0.08em;
    padding-left: 25px;
    color: #0F2044;
    cursor: pointer;
}

.legal .info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
}

.legal .terms {
    max-width: 1110px;
}

.legal .terms-panel {
    width: 100%;
    padding-top: 200px;
}


.legal .privacy-panel {
    width: 100%;
    padding-top: 200px;
    padding-bottom: 100px;
}

.legal .cookies-panel {
    width: 100%;
    padding-top: 200px;
    padding-bottom: 100px;
}

.legal .button-group {
    display: inline-flex;
    flex-direction: row;
    top: 40px;
    position: relative;
}

.legal .customButton {
    height: 50px;
    width: 25vw;
    letter-spacing: 0.5px;
    background-color: transparent;
    color: #0F2044;
    border-radius: 0px;
    cursor: pointer;
    border: 1px solid #0F2044;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    max-width: 350px;
}

.legal .active {
    color: white;
    background-color: #0F2044;
    border: 1.5px solid rgb(25, 26, 24);
}

.legal .customButton:hover {
    color: white;
    background-color: #0F2044;
    border: 1.5px solid rgb(25, 26, 24);
}

.legal .btn-map {
    height: 50px;
	background-color: #008BCD;
	color: white;
	border-radius: 50px;
	cursor: pointer;
	border: none;
	font-family: Work Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 0px;
	text-align: center;
	padding-right: 30px;
	padding-left: 30px;
	margin-top: auto;
	margin-bottom: auto;
	position: relative;
	left: -20px;
}

.legal .btn-map:hover {
    background-color: white;
    color: #06234C;
}
  
.legal h1 {
    font-size: 50px;
    line-height: 64px;
    letter-spacing: 0.07em;
    text-align: left;
    color: #0F2044;
}

.legal .sub-title {
    font-family: 'Hind Madurai';
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
}

.legal h3 {
    font-size: 24px;
    line-height: 20px;
    text-align: left;
    color: #0F2044;
    padding-top: 45px;
}

.legal h5 {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    color: #0F2044;
}

.legal p {
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;
}

.legal ul {
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;
    color: #0F2044;
}

.legal li > a {
    color: blue;
}


.legal hr {
    width: 541px;
    height: 0px;
    opacity: 0.2;
    border: 1px solid #0F2044;
    margin-top: 50px;
}

.no-bullet {
    list-style-type: none;
}
